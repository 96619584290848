*{
  box-sizing: border-box!important;
}

.menu:hover {
  background: rgb(230, 230, 230) !important;
}

.outline-none {
  outline: none;
}

/* input */
.search-input::placeholder {
  color: #999999;
  font-family: regular
}

.search-input::-ms-input-placeholder {
  color: #d3d3d3
}

.scroll-bar::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  padding: 10px;
  margin: 10px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
  padding: 10px;
  margin: 10px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 50px;
  padding: 10px;
  margin: 10px;
}

/* Handle on hover */
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 50px;
  padding: 10px;
  margin: 10px;
}


/* card hover */

.card-hover:hover {
  border-radius: 10px;
  background: #f5f5f5;
}


/* layout */
.top-cont {
  max-width: 1184px;
  margin: 0 auto;
}

.course-info-heading {
  font-size: 22px;
  margin: 0 0 20px 0;
  font-weight: 700;
}

.megamenu-hover:hover {
  color: #ff5612;
  fill: #ff5612;
}

.border-bottom {
  border-bottom: 1px solid #d1d7dc;
}

.border-bottom-2{
  border-bottom-width: 2px!important;
}



.border-top {
  border-top: 1px solid #d1d7dc;
}

.border-left {
  border-left: 1px solid #d1d7dc;
}

.border-right {
  border-right: 1px solid #d1d7dc;
}

.border {
  border: 1px solid #d1d7dc;
}

.border-box {
  box-sizing: border-box;
}

.learning-topic-hover:hover {
  background-color: #d1d7dc;
}

.text-hover:hover {
  color: #ff5612;
}

.profile-menu-hover:hover{
  background-color: #555;
  color: white;
}


.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #000000;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.spin{
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
