body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: thin;
  src: url(./assets/fonts/Montserrat-Thin.ttf);
}
@font-face {
  font-family: light;
  src: url(./assets/fonts/Montserrat-Light.ttf);
}
@font-face {
  font-family: regular;
  src: url(./assets/fonts/Montserrat-Regular.ttf);
}
@font-face {
  font-family: medium;
  src: url(./assets/fonts/Montserrat-Medium.ttf);
}
@font-face {
  font-family: semiBold;
  src: url(./assets/fonts/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: bold;
  src: url(./assets/fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: extraBold;
  src: url(./assets/fonts/Montserrat-ExtraBold.ttf);
}
@font-face {
  font-family: black;
  src: url(./assets/fonts/Montserrat-Black.ttf);
}
@font-face {
  font-family: extraLight;
  src: url(./assets/fonts/Montserrat-ExtraLight.ttf);
}
@font-face {
  font-family: thinItalic;
  src: url(./assets/fonts/Montserrat-ThinItalic.ttf);
}
@font-face {
  font-family: italic;
  src: url(./assets/fonts/Montserrat-Italic.ttf);
}
@font-face {
  font-family: blackItalic;
  src: url(./assets/fonts/Montserrat-BlackItalic.ttf);
}
@font-face {
  font-family: boldItalic;
  src: url(./assets/fonts/Montserrat-BoldItalic.ttf);
}
@font-face {
  font-family: extraBoldItalic;
  src: url(./assets/fonts/Montserrat-ExtraBoldItalic.ttf);
}
@font-face {
  font-family: extraLightItalic;
  src: url(./assets/fonts/Montserrat-ExtraLightItalic.ttf);
}
@font-face {
  font-family: lightItalic;
  src: url(./assets/fonts/Montserrat-LightItalic.ttf);
}
@font-face {
  font-family: mediumItalic;
  src: url(./assets/fonts/Montserrat-MediumItalic.ttf);
}
@font-face {
  font-family: semiBoldItalic;
  src: url(./assets/fonts/Montserrat-SemiBoldItalic.ttf);
}