:root {
    --primary-color: #FF5612;
    --success-color: #147000;
    --danger-color: #730000;
    --dark-500: #131313;
    --dark-400: #1E1E1E;
    --gray-100: #B4B4B4;
    --gray-200: #808080;
    --gray-300: #333333;
}

* {
    -webkit-tap-highlight-color: transparent; /* For iOS */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* For Android */
}


.col-4 {
    display: grid !important;
    grid-template-columns: auto auto auto auto !important;
}

.btn {
    border: 0 !important;
    border-radius: 12px !important;
    cursor: pointer !important;
    color: black !important;
    font-family: semiBold !important;
    height: 48px !important;
    padding: 0 50px !important;
    background: white !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.btn-dark-500 {
    border: 0 !important;
    border-radius: 12px !important;
    cursor: pointer !important;
    color: white !important;
    font-family: semiBold !important;
    height: 48px !important;
    padding: 0 50px !important;
    background: var(--dark-500) !important;
}

.w-100p {
    width: 100% !important;
}

.w-50p {
    width: 50% !important;
}

.overflow-auto {
    overflow: auto !important;
}

.bd-f-blur-5 {
    backdrop-filter: blur(16px) !important;
    -webkit-backdrop-filter: blur(16px) !important;
    background: #000000de !important;
}

.mx-10 {
    margin: 0 10px !important;
}

.p-10 {
    padding: 10px !important;
}

.m-10 {
    margin: 10px !important;
}

.mt-10 {
    margin-top: 10px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.p-0 {
    padding: 0 !important;
}
.p-25 {
    padding: 25px !important;
}

.position-absolute {
    position: absolute !important;
}

.position-relative {
    position: relative !important;
}

.position-static {
    position: static !important;
}

.position-sticky {
    position: sticky !important;
}

.position-fixed {
    position: fixed !important;
}

.top-0 {
    top: 0 !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.left-0 {
    left: 0 !important;
}

.right-0 {
    right: 0 !important;
}


.g-10 {
    gap: 10px !important;
}

.g-20 {
    gap: 20px !important;
}

.g-16 {
    gap: 16px !important;
}

.g-14 {
    gap: 14px !important;
}


.pb-10 {
    padding-bottom: 10px !important;
}

.d-none {
    display: none !important;
}

.d-flex {
    display: flex !important;
}

.a-i-center {
    align-items: center !important;
}

.a-i-end {
    align-items: end !important;
}

.a-i-start {
    align-items: start !important;
}

.a-s-end {
    align-self: end !important;
}

.a-s-start {
    align-self: start !important;
}

.j-c-center {
    justify-content: center !important;
}

.j-c-end {
    justify-content: end !important;
}

.j-c-s-b {
    justify-content: space-between !important;
}

.flex-column {
    flex-direction: column !important;
}

.float-right {
    float: right !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}

.white-space-nowrap {
    white-space: nowrap !important;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis !important;
}

.text-decoration-none {
    text-decoration: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.h-100vh {
    height: 100vh !important;
}

.bg-dark-400-hover:hover {
    background: var(--dark-400) !important;
}
.bg-dark-400-active:active {
    background-color: var(--dark-400) !important;
}

.bg-gray-300-hover:hover {
    background: var(--gray-300) !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.bg-dark-500 {
    background: var(--dark-500) !important;
}

.bg-dark-400 {
    background: var(--dark-400) !important;
}

.fill-gray-100 {
    fill: var(--gray-100) !important;
}

.fill-hover-white:hover {
    fill: white !important;
}

.text-dark-500 {
    color: var(--dark-500) !important;
}

.text-dark-400 {
    color: var(--dark-400) !important;
}

.text-gray-100 {
    color: var(--gray-100) !important;
}

.text-gray-200 {
    color: var(--gray-200) !important;
}

.text-gray-300 {
    color: var(--gray-300) !important;
}

.bg-gray-300 {
    background: var(--gray-300) !important;
}

.bg-gray-200 {
    background: var(--gray-200) !important;
}

.bg-gray-100 {
    background: var(--gray-100) !important;
}
.bg-transparent {
    background-color: #00000000!important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-scroll {
    overflow: scroll !important;
}

.overflow-auto {
    overflow: auto !important;
}

.bg-black {
    background: black !important;
}

.text-black {
    color: black !important;
}

.bg-white {
    background: white !important;
}

.bg-success {
    background: var(--success-color) !important;
}

.bg-danger {
    background: var(--danger-color) !important;
}

.text-white {
    color: white !important;
}

.text-primary {
    color: var(--primary-color) !important;
}

.bg-primary {
    background: var(--primary-color) !important;
}

.border-bottom-2{
    border-bottom-width: 2px!important;
}

.border-bottom-solid{
    border-bottom-style: solid!important;
}

.border-white {
    border-color: white !important;
}

.border-black {
    border-color: black !important;
}

.border-gray-100 {
    border-color: var(--gray-100) !important;
}

.border-dark-500 {
    border-color: var(--dark-500) !important;
}

.border-dark-400 {
    border-color: var(--dark-400) !important;
}

.filter-invert-100 {
    filter: invert(100%) !important;
}

.text-capitalized {
    text-transform: capitalize !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-hover-primary:hover {
    color: var(--primary-color) !important
}

.text-hover-white:hover {
    color: white !important
}

.border-1-s-dark-400 {
    border: 1px solid var(--dark-400) !important;
}

.border-2-s-dark-400 {
    border: 2px solid var(--dark-400) !important;
}

.border-2-s-dark-500 {
    border: 2px solid var(--dark-500) !important;
}

.b-rounded-circle {
    border-radius: 100% !important;
}

.b-radius-10px {
    border-radius: 10px !important;
}

.b-radius-12px {
    border-radius: 12px !important;
}

.ff-bold {
    font-family: bold !important;
}

.ff-semibold {
    font-family: semiBold !important;
}

.ff-medium {
    font-family: medium !important;
}

.ff-regular {
    font-family: regular !important;
}

.text-transform-capitalize {
    text-transform: capitalize !important;
}

.text-transform-uppercase {
    text-transform: uppercase !important;
}

.border-0 {
    border: 0 !important;
}

.scroll-0::-webkit-scrollbar {
    width: 0 !important;
}

/* Customize the scrollbar */
*::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
    background-color: black;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background-color: var(--dark-500); /* Set the color of the scrollbar handle */
    border-radius: 6px; /* Set the border radius of the scrollbar handle */
}

/* Track */
*::-webkit-scrollbar-track {
    background-color: black; /* Set the color of the scrollbar track */
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background-color: var(--dark-400); /* Set the color of the scrollbar handle on hover */
}


@media screen and (max-width: 1024px) {

    .top-0-phone {
        top: 0 !important;
    }

    .position-sticky-phone {
        position: sticky !important;
    }

    .scroll-0-phone::-webkit-scrollbar {
        width: 0 !important;
    }

    .col-4-phone {
        display: grid !important;
        grid-template-columns: auto auto auto auto !important;
    }

    .col-3-phone {
        display: grid !important;
        grid-template-columns: auto auto auto !important;
    }

    .col-2-phone {
        display: grid !important;
        grid-template-columns: auto auto !important;
    }

    .col-1-phone {
        display: grid !important;
        grid-template-columns: auto !important;
    }

    .btn-phone {
        border: 0 !important;
        border-radius: 12px !important;
        cursor: pointer !important;
        color: black !important;
        font-family: semiBold !important;
        height: 38px !important;
        padding: 0 25px !important;
        background: white !important;
        font-size: 12px !important;
    }

    .bg-dark-500-phone {
        background: var(--dark-500) !important;
    }

    .bg-dark-400-phone {
        background: var(--dark-400) !important;
    }

    .btn-dark-500-phone {
        border: 0 !important;
        border-radius: 12px !important;
        cursor: pointer !important;
        color: white !important;
        font-family: semiBold !important;
        height: 38px !important;
        padding: 0 25px !important;
        background: var(--dark-500) !important;
        font-size: 12px !important;
    }

    .overflow-hidden-phone {
        overflow: hidden;
    }

    .p-10-phone {
        padding: 10px !important;
    }

    .p-20-phone {
        padding: 20px !important;
    }

    .p-25-phone {
        padding: 25px !important;
    }

    .p-0-phone {
        padding: 0 !important;
    }

    .px-10-phone {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }

    .py-20-phone {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .m-0-phone {
        margin: 0 !important;
    }

    .m-10-phone {
        margin: 10px !important;
    }

    .mx-10-phone {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }

    .mx-20-phone {
        margin-right: 20px !important;
        margin-left: 20px !important;
    }

    .mb-20-phone {
        margin-bottom: 20px !important;
    }

    .mb-20-phone {
        margin-bottom: 20px !important;
    }

    .mb-10-phone {
        margin-bottom: 10px !important;
    }

    .mt-20-phone {
        margin-top: 20px !important;
    }

    .mt-10-phone {
        margin-top: 10px !important;
    }

    .w-100p-phone {
        width: 100% !important;
    }

    .w-50p-phone {
        width: 50% !important;
    }

    .w-10-phone {
        width: 10px !important;
    }

    .w-20-phone {
        width: 20px !important;
    }

    .w-25-phone {
        width: 25px !important;
    }

    .h-auto-phone {
        height: auto !important;
    }

    .h-10-phone {
        height: 10px !important;
    }

    .h-20-phone {
        height: 20px !important;
    }

    .h-25-phone {
        height: 25px !important;
    }

    .grid-template-100-phone {
        grid-template-columns: auto !important;
    }

    .fs-20-phone {
        font-size: 20px !important;
    }

    .fs-10-phone {
        font-size: 10px !important;
    }

    .fs-12-phone {
        font-size: 12px !important;
    }

    .fs-14-phone {
        font-size: 14px !important;
    }

    .fs-18-phone {
        font-size: 14px !important;
    }

    .fs-16-phone {
        font-size: 16px !important;
    }

    .d-none-phone {
        display: none !important;
    }

    .d-initial-phone {
        display: initial !important;
    }

    .flex-column-phone {
        flex-direction: column !important;
    }

    .flex-wrap {
        flex-wrap: wrap !important;
    }

    .g-0-phone {
        gap: 0 !important;
    }

    .g-10-phone {
        gap: 10px !important;
    }

    .g-20-phone {
        gap: 20px !important;
    }

    .h-auto-phone {
        height: auto !important;
    }

    .b-radius-0-phone {
        border-radius: 0 !important;
    }
}


@media (min-width: 1024px) and (max-width: 1200px) {

    .top-cont-tab {
        max-width: 1024px;
        margin: 0 auto;
    }

    .col-4-tab {
        grid-template-columns: auto auto auto auto !important;
    }

    .col-3-tab {
        grid-template-columns: auto auto auto !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .top-cont-minitab {
        max-width: 768px;
        margin: 0 auto;
    }

    .col-3-minitab {
        grid-template-columns: auto auto auto !important;
    }

    .col-2-minitab {
        grid-template-columns: auto auto !important;
    }

    .col-1-minitab {
        grid-template-columns: auto !important;
    }
}

