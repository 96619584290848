@media screen and (max-width: 1024px) {
    .top-cont{
        margin: 10px;
    }
    .author-section-photo{
        width: 100px!important;
        height: 100px!important;
    }
    .author-photo-phone{
        width: 25px!important;
        height: 25px!important;
    }

    .hero-info-phone{
        position: relative!important;
    }
    .hero-photo-phone{
        border-radius: 12px!important;
    }
    .price-phone{
        font-size: 25px!important;
    }
    .discounted-price-phone{

    }
    .buy-now-button-phone{
        width: 132px!important;
    }
    .topic-container-phone{
        padding: 15px 30px!important;
    }

}
