:root {
    --primary-color: #FF5612;
    --success-color: #147000;
    --danger-color: #730000;
    --dark-500:#131313;
    --dark-400: #1E1E1E;
    --gray-100: #B4B4B4;
    --gray-200: #808080;
    --gray-300: #333333;
}

@media screen and (max-width: 1024px){
    .logo-cont-phone{
        margin-bottom: 50px!important;
    }
    .footer-cont-phone{
        margin-bottom: 40px!important;
    }
}
