


@media screen and (max-width: 1024px) {
    .description-box-phone{
        height: 128px!important;
    }
    .topic-cart-phone{
        height: 68px!important;
    }
}
